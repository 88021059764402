import { createApp } from 'vue'
import { baseUrl } from 'Shared/lib/base-url'
import App from './App.vue'
import router from '@/router'
import http from 'Shared/lib/http'
import ActionCableVue from "actioncable-vue";
import VueGtag from "vue-gtag";

const actionCableVueOptions = {
  debug: process.env.NODE_ENV === 'development',
  debugLevel: "error",
  connectionUrl: baseUrl('ws', '/ws'),
  connectImmediately: true,
};

const app = createApp(App)
  .use(router)
  .use(ActionCableVue, actionCableVueOptions)
  .use(http, {
    baseURL: baseUrl(undefined, '/api/v1'),
    csrfHeader: true,
    headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'x-my-ver': '100',
    },
    authorization: () => localStorage.token && `bearer ${localStorage.token}` || null,
    onUnauthorized: () => {
      
    }
  })
  .use(VueGtag, {
    config: { id: 'G-XFFHRRBSDB' }
  }, router);

app.component('LoadingSpinner',  require('Shared/components/loading-spinner').default);
app.component('SegmentedButton', require('Shared/components/segmented-button').default);
app.component('DisclosureArrow', require('Shared/components/disclosure-arrow').default);

app.mount('#app')

window.__myavpaApp = app;