<template>
  <ul class="segmented-button">
    <li 
      v-for="choice in choices" 
      :key="choice.value" 
      :class="{button:true, mini: true, selected: transform(modelValue)==choice.value}"
      @click.prevent="choose(choice)"
    >
      {{ choice.label }}
    </li>
  </ul>
</template>

<style lang="scss">
  .segmented-button {
    margin: 0; padding: 0; list-style:none;
    border-radius: var(--br);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    &.error, &.err {
      border: 2px solid var(--input-error-bgcolor);
      margin-left: -2px;
      margin-top: -2px;
    }
    
    li { 
//      display: inline-block;
      border-radius: 0;
      min-height: 44px;
      line-height: 44px !important;
      overflow: hidden;
//      text-overflow: ellipsis;
      
      &:first-child { 
        border-top-left-radius: var(--br); 
        border-bottom-left-radius: var(--br); 
      }
      &:last-child  { 
        border-top-right-radius: var(--br); 
        border-bottom-right-radius: var(--br); 
      }
    }
  }
</style>

<script>
export default {
  props: {    
    modelValue: {
      type: [String,Object,Boolean],
      required: false,
      default: () => undefined,
    },
    choices: {
      type: Array,
      required: true
    },
    transform: {
      type: Function,
      default: val => val
    }
  },
  
  emits: ['update:modelValue'],
  
  data() {
    return {
      selectedValue: undefined // initialized in mounted
    }
  },
  
  watch: {
    value(newVal) { 
      console.debug("segmented-button: value prop changed", newVal);
    }
  },
  
  mounted() {
    console.log("mounted; transform", this.modelValue, this.transform(this.modelValue));
    this.selectedValue = this.transform(this.modelValue);
  },
  
  methods: {
    choose(choice) {
      console.debug("transform", choice?.value);
      let transformedValue = this.transform(choice?.value);
      this.selectedValue = transformedValue;
      this.$emit('update:modelValue', transformedValue);
    }
  }
}
</script>