<template>
  <transition name="sa">
    <svg class="sa status-animation" v-if="isSuccess" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
        <path d="M130.107,295.464L207.008,383.237L394.265,139.983" style="fill:none"/>
    </svg>
  </transition>
</template>

<style lang="scss">

.sa {
  background: var(--success-color);
  border-radius: 50%;
  
  path {
    stroke: black;
    stroke-width: 45px;
    stroke-dashoffset: 0;
    stroke-dasharray: 570;
  }
}

.sa-enter-active {
  transition: all 0.4s ease-out;
  path {
    animation: checkmark 0.2s linear backwards;
    animation-duration: 0.2s;
    animation-delay: 0.2s;
  }
}

.sa-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  path {
    transition: all 0.3s ease-out;
  }
  
}

.sa-enter-from,
.sa-leave-to {
  transform: scale(0) rotate(22.5deg);
  opacity: 0;
  path {
    stroke-dashoffset: 570;
    stroke-dasharray: 570;
  }
}

@keyframes checkmark {
  from {
    stroke-dashoffset: 570;
  }

  to {
    stroke-dashoffset: 0;
  }
}

</style>

<script>
export default {
  props: {
    status: {
      type: String,
      required: false,
      default: () => 'indeterminate'
    }
  },
  
  computed: {
    isSuccess() { return this.status == 'success'; }
  }
}
</script>
