let _currentAuth;
export function currentAuth() {
  if (!_currentAuth) {
    let tok = localStorage.token;

    if (!tok) return null;
  
    try {
      _currentAuth = decodeToken(tok);
    }
    catch(e) {
      console.error("Could not decode token", e);
      _currentAuth = false;
    }
  }
  
  return _currentAuth;
}

export function setCurrentAuth(username, token, expiresAt, renewalToken, clearEmail=false) {
  // console.debug(`setCurrentAuth u:${username} token:${token} exp:${expiresAt} (clearEmail? ${clearEmail})`);
  if (username || clearEmail)
    localStorage.un = username ? btoa(username) : undefined;;
  localStorage.token = token;
  localStorage.tokenExpiresAt = JSON.stringify(expiresAt && new Date(expiresAt).getTime() || undefined);
  localStorage.renewal = renewalToken;
  _currentAuth = null;
}

export function clearAuth(clearEmail=false) {
  setCurrentAuth(null, null, null, null, clearEmail);
}


export function getLastUsername() {
  const data =localStorage.un;
  if (!data || !data.length || data === 'undefined')
    return undefined;
  return data && atob(data);
}

export function isLoggedIn(iss) {
  let auth = currentAuth();
  return !!auth?.sub && auth?.iss===iss;
}

export function isAuthorized(scopes=[]) {
  if (!Array.isArray(scopes))
    scopes = [scopes];
  
  if (!scopes.length) {
    console.debug('isAuthorized called without scopes');
    return undefined;
  }
  
  let auth = currentAuth();
  const authorizedScopes = auth?.scope?.split(' ') || [];
  return (scopes.every(s=> authorizedScopes.includes(s)))
}

export function getAuthIsExpired() {
  const auth = currentAuth();
  return auth && (auth.exp <= (Date.now() / 1000))
}

export function renewAuth() {
  const rtok = localStorage.renewal;
  const un   = getLastUsername();

  return new Promise((resolve, reject) => {
    const resetTokAndReject = (reason, err) => {
      if (reason) console.error(reason);
      setCurrentAuth(un, null, undefined, null);
      reject(err);
    }
    
    if (!rtok || !rtok.length || rtok=='undefined')
      resetTokAndReject('rtok blank');
    
    if (!un || !un.length || un=='undefined') 
      resetTokAndReject('un blank');
      
    console.debug("renew with rtok", rtok);
    const axios = __myavpaApp.config.globalProperties.axios;
    axios.request({
      method: 'post',
      url: 'refresh_auth',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      data: {
        u: un,
        r: rtok, 
      }
    })
    .then(r=> {
      let data = r.data;
      setCurrentAuth(un, data.token, data.expiresAt, data.renewal);
      resolve();
    })
    .catch(err=> resetTokAndReject('req failed', err));
    
  });
}






export function decodeToken(tok) {
  if (!tok || !tok.length || tok == 'undefined')
    return false;
  
  try {
    const base64Url = tok.split('.')[1];
    if (!base64Url) return false;
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }
  catch (e) {
    console.error('Could not decode token', e);
    console.warn(`Token was '${tok}'`);
  }
}