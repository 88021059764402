<template>
  <div class="login">
    <h1>{{ stateName }}</h1>
    
    <p v-if="message" class="error">{{message}}</p>

    <form ref="loginbox" class="group" :class="{shake: isShaking}" @submit="onFormSubmit">
      <loading-spinner v-if="loading" />
      <div class="input-group stretch">
        <input ref="un"
          :class="{single: state=='indeterminate'}"
          v-model="un" 
          type="email" 
          placeholder="Email Address" 
          autocomplete="username"
          :disabled="state != 'indeterminate'"
          autofocus 
          @keyup.prevent="detectReturn"
          @keydown.stop>
      
        <button class="clear" @click="clearEmail" ref="clearButton">x</button>
      </div>
      
      <transition-group name="ls" tag="div" class="stretch">
        <fieldset id="password" class="bottom-join" v-if="state=='login'">
          <input ref="pw" v-model="pw" type="password" placeholder="Password" autocomplete="current-password" @keyup="detectReturn">
        </fieldset>

        <fieldset id="password" class="bottom-join" v-if="state=='password_reset'">
          <input ref="pw"  :class="{err:hasErr('pw')}"  v-model="pw"  type="password" placeholder="New Password" autocomplete="new-password" class="stack" />
          <input ref="pwc" :class="{err:hasErr('pwc')}" v-model="pwc" type="password" placeholder="Confirm Password" autocomplete="new-password" />
        </fieldset>

        
        <fieldset id="signup" class="bottom-join" v-if="state=='signup'">
          <input ref="pw"  :class="{err:hasErr('pw')}"  v-model="pw"  type="password" placeholder="New Password" autocomplete="new-password" class="stack" />
          <input ref="pwc" :class="{err:hasErr('pwc')}" v-model="pwc" type="password" placeholder="Confirm Password" autocomplete="new-password" />
          
          <label>Name</label>
          <input ref="fn" :class="{err:hasErr('fn')}" v-model="signup.fn" type="text" placeholder="First" autocomplete="given-name">
          <input ref="ln" :class="{err:hasErr('ln')}" v-model="signup.ln" type="text" placeholder="Last" autocomplete="family-name">
          
          <div class="form-row inline half">
            <div>
              <label>Gender</label>
              
              <segmented-button ref="g" :class="{err: hasErr('g')}" v-model="signup.g" :choices="[
                {label: 'M', value: 'm', title: 'Male'},
                {label: 'F', value: 'f', title: 'Female'},
                {label: 'O', value: 'i', title: 'Other / Prefer not to say'},
              ]" />
            </div>
          </div>
          
          <div class="form-row inline half">
            <div>
              <label>Age</label>
              <input ref="age" type="number" v-model="signup.age" class="mini" :class="{err:hasErr('age')}">
            </div>
          </div>
        </fieldset>
        
      </transition-group>

      <div class="controls">
        <button type="button" class="round go" @click="doCancel" v-if="state!='indeterminate'">❌</button>
        <button type="button" class="round go" @click="doLogin">→</button>
      </div>
      
      <div class="controls">
        <a href="#" v-if="un && state == 'login'" @click="showPasswordReset">Forgot Password?</a>
      </div>

      <div class="password-reset box" v-if="passwordResetState">
        <button class="round close" @click="hidePasswordReset">⨯</button>
        <template v-if="['preRequest', 'requesting'].includes(passwordResetState)">
          <loading-spinner v-if="passwordResetState=='requesting'"/>
          <h2>Password Reset</h2>
          <p v-if="un">We'll send a link to {{ un }} where you can choose a new password</p>
          <p v-else>Enter your email address above to have a password reset link sent</p>
          <button class="full" :disabled="!un" @click="doRequestPasswordReset">Send Reset Link</button>
        </template>
        <template v-if="passwordResetState=='requested'">
          <h2>Password Reset Requested</h2>
          <p>Please check your email for a link to reset your password.</p>
          <button class="full" @click="hidePasswordReset">Done</button>
        </template>
        <template v-if="passwordResetState=='error'">
          <h2>Password Reset Error</h2>
          <p>We're sorry, an error occurred while requesting a password reset.</p>
        </template>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-2px, 0, 0);
    }
  
    20%, 80% {
      transform: translate3d(4px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-8px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(8px, 0, 0);
    }
  }

  .login {
    .group {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 90vw;
      max-width: 337px;
      margin: 0 auto;
      
      // transition-group div
      .stretch { width: stretch; }
      
      fieldset {
        width: stretch;
        margin: 0; padding: 0;
        >input { margin: 0 auto; width: stretch;}
        &.space { margin-top: 1em; }
      }
  
      input { 
        display: block; 
        width: stretch;
        border-radius: 8px;
        line-height: 2em;
        font-size: 1.25em;
        -webkit-appearance: none;
        background-color: var(--input-bgcolor);
        color: var(--text-color);
        border: 1px solid rgba(0,0,0,0.8);
        padding: 0.5em 1em;
        max-width: 400px;
        
        transition: border-radius 0.4s ease;
      }
      
      .input-group {
        position: relative; 
        
        button.clear {
          position: absolute;
          right: 0.5em;
          top: 50%;
          transform: translateY(-50%);
          user-select: none;
        }
      }
      
      .err {
        background: var(--input-error-bgcolor);
      }
  
      input:first-child:not(.single):not(.bottom-join input) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      input+input, .bottom-join input:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      input.stack {
        border-radius: 0;
      }
      
      label {
        display: block;
        text-align: left;
        margin-top: 0.5em;
        margin-left: 1.35em;
        font-weight: bold;
      }
      
      .spinner {
        position: absolute;
        bottom: -1em;
      }
  
      button.go {
        --button-round-size: 56px;
        margin: 1em 0.5em 0;
      }
  
      &.shake input,
      &.shake .segmented-button,
      &.shake select {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
    
    .controls {
      margin-top: 2em;
//      padding-bottom: 5em;
    }
    
    .password-reset {
      padding: 1em 1.5em 1em;
      max-width: 400px;
      position: relative;
      h2 { margin: 0; }
      button.close { 
        --button-round-size: 32px;
        background: var(--text-dim3-color); 
        border-color: var(--neg-color); 
        position: absolute; top: 2px; right: 2px;
      }
      button.full  { width: stretch; height: 44px; }
    }
  }
</style>

<script>

import { nextTick } from 'vue'
import { getLastUsername, setCurrentAuth, clearAuth } from 'Shared/lib/authentication'
import { parseQuery } from 'Shared/lib/query'

export default {
  data() {
    return {
      state: 'indeterminate', // login, signup
      loading: false,
      isShaking: false,
      
      message: null,
      fieldErrors: [],
      
      un: getLastUsername(),
      pw: '',
      pwc: '',
      
      signup: {
        g: undefined
      },
      
      passwordResetState: undefined,
    }
  },
  
  computed: {
    stateName() {
      switch (this.state) {
        case 'indeterminate': return "Aravaipa Group";
        case 'login': return 'Sign In';
        case 'signup': return 'Sign Up';
        case 'password_reset': return 'Password Reset';
      }
      return 'Log In';
    },
    
    attemptedPath(){
      return this.$route.query.t || '/';
    }
  },
  
  mounted() {
    if (this.$route.query.pr) {
      // we have a password reset token; need to show that
      this.state = 'password_reset';
      this.focusField('pw');
    }
  },
  
  methods: {
    clearEmail(e) {
      if (document.activeElement !== this.$refs.clearButton)
        return;

      e && e.preventDefault();
      this.un = '';
      clearAuth(true);
      this.focusField('un');
    },
    
    detectReturn(e) {
      // console.log(`detectReturn ${e.key}`);
      // Don't let the event continue to propogate to other elements,
      // but allow the default handlerto proceed.
      e.stopPropagation();
      
      if (e.key === 'Enter') {
        console.log("detected return; calling doLogin()");
        e.preventDefault();
        e.target.blur();
        this.doLogin();
      }
    },
    
    focusField(refName) {
      console.debug(`focusField ${refName}`);
      nextTick(()=> {
        let el = this.$refs[refName];
        if (el) {
          el.focus();
          el.select();
          console.debug('focusField: click el', el);
          el.click(); //TODO: figure out how to show the password autofill on iOS
        }
      })
    },
    
    onFormSubmit(e) {
      console.debug('onFormSubmit.', e.target);
      if (e) {
        e.preventDefault();
        // e.stopPropagation();
      }
      // this.doLogin(e);
    },
    
    doCancel() {
      this.state = 'indeterminate'
      this.focusField('un');
    },
    
    doLogin(e) {
      console.debug(`doLogin (currently loading ${this.loading})`);
      if (this.loading) return;
      switch (this.state) {
        case 'indeterminate': {
          // We don't know if we're signing in or signing up.
          // Ask the server if the email address is recognized.
          //
          this.checkAccount();
          break;
        }
        case 'login': {
          // Account should exist; try obtaining a token with
          // the given password
          this.sendLogin();
          break;
        }
        case 'signup': {
          // Account doesn't exist; send the signup fields
          // and obtain a token by default.
          this.getSignupFormErrors();
          if (this.fieldErrors.length) {
            // Handle errors
            const err = this.fieldErrors[0];
            this.focusField(err?.ref);
          }
          else {
            // no errors
            this.sendSignup();
          }
          break;
        }
        case 'password_reset': {
          // Account probably exists, but we're not validating
          // anything but that the passwords match
          //
          this.getSignupFormErrors();
          if (this.fieldErrors.length) {
            // Handle errors
            const err = this.fieldErrors[0];
            this.focusField(err?.ref);
          }
          else {
            // no errors
            this.sendPasswordReset();
          }
          break;
        }
      }
    },
    
    sendSignup() {
      let queryVars = parseQuery(window.location.search);
      
      let account = {
        email: this.un,
        password: this.pw,
        password_confirmation: this.pwc,
        signup_origin: queryVars.o,
        ...this.signup
      };
      
      this.axios.request({
        method: 'post',
        url: `accounts`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: { account }
      })
      .then(r=> {
        /* Receive: 
         *  - An authentication token if part of sign up flow
         *  - a hint about wavers that need to be signed
         *  - The created account, mod credentials.
         */
        console.log("signup response: ", r);
        if (r.data.tok) {
          this.$gtag.event('login', { method: 'MyAravaipa', origin: queryVars.o });
          setCurrentAuth(this.un, r.data.tok, r.data.expiresAt, r.data.renewal);
        }
        
        // If we need to sign a waver, find that now.
        const router = this.$router;
        const query = router.currentRoute.value.query;
        if (r.data.waverId) {
          let newRoute = {name: 'waver', query: query, params: {waverId: r.data.waverId} };
          router.push(newRoute);
        }
        else {
          this.$gtag.event('signup');
          const to = { fullPath: query.t || '/', query: query }
          router.push(to);
        }
      })
      .catch(err=> {
        console.error("Signup Error", err);
        const resp = err.response;
        if (resp.status >= 500) {
          alert("We've experienced an error processing your signup. Please try again");
        }
      })
    },
    
    checkAccount() {
      if (!this.un || !this.un.length) {
        this.loginDidFail();
        return;
      }
      
      this.message = null;
      
      this.axios.request({
        method: 'get',
        url: `authenticate?tok=${btoa(this.un)}`,
        headers: {
          'Accept': 'application/json',
        },
      })
      .then(r=> {
        // 200 
        console.log("Check response:", r);
        this.state = 'login';
        this.focusField('pw');
      })
      .catch(e=> {
        const code = e.response?.status;
        if (!code || code >= 500) {
          console.error("Server error", e);
          this.message = "Can't seem to contact the server…";
          return;
        }
        
        this.state = 'signup';
        this.focusField('pw');
      })
    },
    
    sendLogin() {
      if (this.loading) return;
      this.message = null;
      this.loading = true;
      console.log("Doing Sign In");
      
      this.axios.request({
        method: 'post',
        url: 'authenticate',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        data: {
          u: this.un,
          p: this.pw, 
        }
      })
      .then(r=> {
        console.log("Login Response:", r);
        if (!r) { 
          this.loginDidFail();
        }
        else {
          let data = r.data;
          setCurrentAuth(this.un, data.token, data.expiresAt, data.renewal);
          this.loading = false;
        
          this.loginDidSucceed();
        }
      })
      .catch(err => {
        console.error("Login", err); 
        this.loginDidFail()
      })
    },
    
    sendPasswordReset() {
      this.loading = true;
      
      this.axios.request({
        method: 'put',
        url: 'account/password_reset ',
        data: {
          u: this.un,
          tok: this.$route.query.pr?.replace(/\ /g, '+'),
          p: this.pw,
          pwc: this.pwc,
        }
      })
      .then(r=> {
        console.log("Password Reset Response:", r);
        if (!r) { 
          this.loginDidFail();
        }
        else {
          let data = r.data;
          setCurrentAuth(this.un, data.token, data.expiresAt, data.renewal);
          this.loading = false;
          this.loginDidSucceed();
        }
      })
      .catch(err => {
        console.error("pass reset err", err); 
        this.loginDidFail()
      })
    },
        
    getSignupFormErrors() {
      let errors = [];
      this.fieldErrors = errors;
      
      if (!this.pw || !this.pw.length) {
        errors.push({ref: 'pw', error: "Password can't be blank"});
      }
            
      // Check password confirmation
      if (this.pw != this.pwc) {
        errors.push({ref: 'pwc', error: 'Passwords do not match'});
      }
      
      if (this.state != 'password_reset') {
        if (!this.signup.fn || !this.signup.fn.length) {
          errors.push({ref: 'fn', error: "First Name can't be blank"});
        }

        if (!this.signup.ln || !this.signup.ln.length) {
          errors.push({ref: 'ln', error: "Last Name can't be blank"});
        }
      
        const age = this.signup.age;
        if (undefined === age || null === age || age < 1 || age > 150) {
          errors.push({ref: 'age', error: "Age is required"});
        }
      
        if (!['m','f','i'].includes(this.signup.g)) {
          errors.push({ref: 'g', error: "Gender is required"});
        }
      }
      
      if (errors.length) {
        this.shakeLogin();
      }
      
      return errors;
    },
    
    hasErr(ref) {
      return this.fieldErrors.find(err=> err.ref == ref);
    },

    shakeLogin() {
      console.log("will shake login");
      this.isShaking = true
      const elm = this.$refs.loginbox;
      const stopShaking = (e) => { 
        this.isShaking = false;  
        elm.removeEventListener("webkitAnimationEnd", stopShaking);
        elm.removeEventListener("animationend", stopShaking);
        elm.removeEventListener("oanimationend", stopShaking);
      };
      elm.addEventListener("webkitAnimationEnd", stopShaking);
      elm.addEventListener("animationend", stopShaking);
      elm.addEventListener("oanimationend", stopShaking);
    },
    
    loginDidFail() {
      this.loading = false;

      if (this.$refs.pw) {
        this.$refs.pw.focus(); 
        this.$refs.pw.select()
      }
      else {
        // If the password field is not shown,
        // we probably need to select the username
        this.$refs.un.focus(); 
        this.$refs.un.select()
      }

      this.shakeLogin();
    },
    
    loginDidSucceed() {
      this.message = null;
      const p = this.attemptedPath;
      console.log("redirecting: ", p);
      this.$router.replace(p);
    },
    
    showPasswordReset(e) {
      e && e.preventDefault();
      this.passwordResetState = 'preRequest';
    },
    
    hidePasswordReset(e) {
      e && e.preventDefault();
      this.passwordResetState = undefined;
    },
    
    doRequestPasswordReset(e) {
      e && e.preventDefault();
      this.passwordResetState = 'requesting';

      this.axios.request({
        method: 'post',
        url: 'account/reset',
        data: {
          u: this.un,
          t: this.attemptedPath
        }
      }).then(r => {
        this.passwordResetState = 'requested';
      })
      .catch(err => {
        this.passwordResetState = 'error';
        console.error("password reset error", err);
      })
    }
  }
}
</script>