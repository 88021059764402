// Development:
//   - fetch localhost:3000
//   - intranet 192.168.1.101:3000
//   - websocket: ws://
//
// Production:
//   - fetch proddomain.com
//   - websocket wss://proddomain.com (secure) 
//
export function baseUrl(protocol, basePath, path=null) {
  let host;
  if (!protocol) protocol = location.protocol;
  if (!protocol.endsWith(':')) protocol += ':';
  if (process.env.NODE_ENV == 'development') {
    //console.log(`Development (prot ${protocol})`);
    if (protocol == 'https:') protocol = 'http:';
    host = protocol + '//' + location.hostname;
    host += ':3000' // front end dev server is on a different port
  }
  else {
    console.log("Production");
    if ('ws:'===protocol) protocol = 'wss:';
    host = protocol + '//' + location.host;
  }

  console.log(`baseUrl: ${host + basePath}`)
  
  let u = host + basePath;
  if (path) {
    if (Array.isArray(path))
      path = path.join('/');

    path = String(path);
    
    if (path.startsWith && !path.startsWith('/') && !u.endsWith('/'))
      u += '/';
    
    u += path;
  }
  
  return u;
}