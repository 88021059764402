<template>
  <main class="profile">
    <header class="main">
      <nav>
        <div class="bar">
          <span class="left">
            <button class="blank" @click="toggleMenu">&#x2630;</button>
          </span>
          <h1>Aravaipa Group Trail Run</h1>
          <span class="right">
            <span class="flexible-space">&nbsp;</span>
          </span>
        </div>
        <ul v-if="showMenu" class="main-menu">
          <li @click="doSignOut">Sign Out</li>
        </ul>
      </nav>
      
      <div class="profile">
        <loading-spinner v-if="loading" />
        
        <!-- <figure class="avatar" @click="askForAvatar">
          <img alt="profile" :src="avatarUrl">
        </figure> -->
        <figure class="avatar" :class="{loading: avatarUploadProgress, done: avatarUploadProgress >= 100}" @click="askForAvatar">
          <svg viewBox="0 0 36 36">
            <path
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke="#f00"
              stroke-width="1"
              :stroke-dasharray="`${avatarUploadProgress}, 100`"
            />
          </svg>
          <img alt="profile" :src="avatarUrl">
        </figure>
        
        <div class="info"> 
          <h1 class="name" @click="showProfileEdit">
            <span v-if="account">{{ account.fn }} {{ account.ln }}</span>
            <disclosure-arrow />
          </h1>
          <h3 class="detail">
            <span v-if="account && joinTimeAgo">Joined {{ joinTimeAgo }}</span>
            <span v-if="account && account.checkIns.length" class="history">{{ account.checkIns.length }} Check-Ins</span>
          </h3>
        </div>
      </div>
    </header>
    
    <section v-if="showDeetsBox" class="deets box" ref="deetsBox">
      <h2>Gotta stay safe— please provide emergency contact info</h2>
      <form @submit="cancelEvent">
        <div class="form-row">
          <label>Shirt Size</label>
          <select v-model="accountChanges.shirtSize">
            <option value="XS">Extra Small</option>
            <option value="S">Small</option>
            <option value="M">Medium</option>
            <option value="L">Large</option>
            <option value="XL">Extra Large</option>
            <option value="XXL">XXL</option>
          </select>
          <label>Emergency Contact Name</label>
          <input type="text" v-model="accountChanges.emergencyName">
          <label>Emergency Contact Phone</label>
          <input type="text" v-model="accountChanges.emergencyPhone">
        </div>
        <div class="controls">
          <button @click="doSendProfileInfo">Send</button>
        </div>
      </form>
    </section>
      
    <ul class="modules">
      <li class="gr">
        <router-link v-if="!isCheckedIn" to="/check-in">Show <em>Check In Code</em></router-link>
        <router-link v-else to="/check-in">Group Run <em>Checked In ✅</em></router-link>
      </li>
      <li class="disabled">Volunteer <em>Credits</em></li>
      <li class="disabled">Race <em>Results</em></li>
    </ul>

    <section v-if="account" class="check-in-history box">
      <h2>History</h2>
      <ul class="table" v-if="account.checkIns">
        <li v-if="account.checkIns.length<1" class="no-records">No check-ins yet</li>
        <li v-for="ci in account.checkIns" :key="ci.id" @click="showCheckIn(ci)">
          <h3 class="title">{{ ci.name }}</h3>
          <span class="detail">{{ checkInDetails(ci) }}</span>
          <span v-if="ci.checkOutRequired && !ci.canceled" class="flag">Check Out</span>
          <disclosure-arrow />
        </li>
      </ul>
    </section>    
    <transition name="qr">
      <qr-code v-if="qrCodeData" :qr-data="qrCodeData" @close="cancelQRCode">
        <h1>Show to Host if Requested</h1>
        <template v-slot:after>
          <span>Name: {{ account.fn }} {{ account.ln }}</span>
        </template>
      </qr-code>
    </transition>
    
    <input ref="fileInput" style="display:none" type="file" @change="handleNewAvatarFile">
    
    <article v-if="showDeetsBox" class="modal-cover">&nbsp;</article>
  </main>
</template>

<style lang="scss">
  
  .deets {
    text-align: left;
    position: fixed; z-index: 11;
    top: 20vh;
    box-shadow: 0 0 15px rgba(0,0,0,0.8);

    h2 { 
      font-size: 1em; margin: 0em; background: var(--accent-color);
      border-top-left-radius: var(--br);
      border-top-right-radius: var(--br);
      padding: 0.5em;
    }

    form {
      padding: 1em 0.5em;
      max-width: 440px;
      .form-row {
        display: block;
        label { display: block; margin: 0; padding: 0; text-align: left !important;}
        input,select { 
          width: stretch;
          max-width: unset;
          font-size: 18px;
          padding: 0.25em 0.5em;
          background: var(--input-bgcolor);
          color: var(--text-color);
        }
        
        input, select {  }
      }
      
      .controls button { width: stretch; min-height: 44px; margin-top: 1em;}
    }
  }
  
  header.main {
    min-height: 200px;
      
    nav {
      min-height: 44px;
      box-shadow: inset 0 2px 15px rgba(0,0,0,0.3);
//      background: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,0.3) 100%),
//                  linear-gradient(to right, #914200 0%, #DE6400 30%,  #007C91 100%),;
      background: linear-gradient(to right, var(--accent-color) 0%, var(--header-accent-color) 100%),;
      transition: height 0.4s ease;
      color: #fff;
      
      h1 {
        font-size: 18px;
        text-align: center;
        text-shadow: 0 -1px 1px rgba(0,0,0,0.7);
      }

      .bar {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .left { position: absolute; left: 0; display: flex; justify-content: left; }
        .right { position: absolute; right: 0; display: flex; justify-content: right; }
      }
      
      .main-menu {
        text-align: left;
        margin: 1em; padding: 0 0 1em 0;
        list-style: none;
        
      }
    }
    
    .profile {
      display: flex;
      text-align:left;
      margin-top: 1em;
      
      h1,h2,h3,h4 {
        margin: 0;
      }

      .avatar {
        height: 96px;
        width: 96px;
        border-radius: 50%;
        overflow:hidden;
        aspect-ratio: 1;
        flex-grow: 0;
        flex-shrink: 0;
        box-shadow: 0 0 0 5px var(--text-color), 0 5px 10px rgba(0,0,0,0.4);
        margin: 1em 1.5em 0 22px;
        border: 4px solid var(--accent-color);
        // Safari does not apply border-radius to outlines apparently.
        //outline: 1px solid var(--text-color);
        
        position: relative;
        
        
        img { 
          border: none;
          position:absolute;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
                
        svg { position: absolute; width: 96px; height: 96px; opacity: 1; transition: opacity 1s ease; }
        &.loading { border-color: rgba(0,0,0,0); }
      
        // Fade out progress ring on completion
        &:not(.loading) svg { opacity: 0; }
        &.done svg { opacity: 0;  }
      }
      
      .avatar-wrapper {
        position: relative;
        .avatar-tip {
          position: absolute;
          left: 0; right: 0;
          bottom: -1.5rem;
          text-align: center;
        }
      }

      
      
      .info { flex: 1;} 
      
      .name {
        margin-top: 1em;
        position: relative;
        user-select: none;
        pointer-events: all;
        .disclosure-arrow { 
          fill: var(--text-dim3-color);
          height: 2em;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index:0;
        }
      }
      
      .detail {
        font-weight: normal;
        color: var(--text-dim1-color);
        display:block;
        &>* {
          white-space: nowrap;
          display: inline-block;
        }
//        &>*:not(:last-of-type)::after {
//          content: '|';
//          margin: 0 1ex;
//        }
      }
    }
  }
  
  .modules {
    margin: 0; padding: 0 0 0 1em; list-style:none;
    // phone
    display: flex;
    flex-wrap: wrap;
    

    li {
      background: var(--app-bgcolor);
      border-radius: var(--br);
      padding: 1em;
      min-height: 64px;
      text-align: left;
      font-size: 16px;
      margin: 1em 1em 0 0;
      box-sizing: border-box;
      border-left: 2px solid var(--light-accent-color);
      overflow: hidden;
      
      flex-basis: calc(50vw - 1.5rem);
      
      &.disabled { 
        color: var(--text-dim3-color);
        border-left: 2px solid var(--dark-accent-color);
      }
      
      a { 
        color: var(--text-color);
        text-decoration: none; 
      }
      
      em {
        font-style: normal;
        font-weight: 900;
        font-size: 1.25rem;
        display: block;
        white-space: nowrap;
      }
    }
  }
  
  .check-in-history {
    text-align: left;
    padding-top: 0.15rem;
    &>h1,&>h2,&>h3 { margin-left: 1rem; }
    
    .flag { 
      position: absolute;
      top: 50%; right: 28px;
      transform: translateY(-50%);
      background: var(--error-color);
      border-radius: var(--br-sharp);
      text-shadow: 0 -1px 1px black;
      padding: 2px 6px;
    }
  }
</style>

<script>
import { nextTick } from 'vue'
import { timeAgoInWords, shakeElement } from 'Shared/lib/utils'
import { isLoggedIn, currentAuth, clearAuth } from 'Shared/lib/authentication'
import ActionButton from '@/components/action-button'
import QrCode from 'Shared/components/qr-code'

export default {
  name: 'Profile',
  components: { QrCode },
  
  data() {
    return {
      loading: false,
      account: undefined,
      accountChanges: {},
      
      avatarFileBlobURL: null,
      avatarUploadProgress: 0,
      
      showMenu: false,
      // lastCheckIn: null,
      
      qrCodeName: undefined,
      qrCodeData: undefined
    }
  },
  
  computed: {
    avatarUrl() {
      if (this.avatarFileBlobURL)
        return this.avatarFileBlobURL;
      
      if (!this.account || !this.account.a) 
        return 'https://www.gravatar.com/avatar/?s=128&d=mp&f=y';

      return this.account.a;
    },
    
    showDeetsBox() {
      if (!this.account) return false;
      if (!this.account.emergencyPhone) return true;
      return false;
    },
    
    joinTimeAgo() {
      // console.log("joined at -> ", this.account.createdAt, new Date(this.account.createdAt));
      if (!this.account || !this.account.createdAt) return false;
      return timeAgoInWords(new Date(this.account.createdAt));
    },
    
    lastCheckIn() {
      let c = this.account?.checkIns || [];
      return c[0];
    },
    
    isCheckedIn() {
      if (!this.lastCheckIn) return false;
      if (!this.lastCheckIn.endsAt) return false;
      if (this.lastCheckIn.canceled) return false;
      
      let ea = new Date(this.lastCheckIn.endsAt);
      // console.debug("check in expires at ", ea.getTime(), Date.now());
      return Date.now() < ea.getTime();
    }
  },
  
  watch: {
    $route(to, from) {
      this.checkRouteForQR(to);
    }
  },
  
  channels: {
    ClientChannel: {
      connected() {
        console.log("Connected to ClientChannel");
      },
      rejected() {},
      received(data) {
        console.log("Received", data)
        if (data.action == 'check_in') {
          this.qrCodeData = null; // reset QR code display
          let checkIn = data.checkIn;
          if (data.accountId != this.account?.id) {
            console.error(`Received checkIn for mismatched account (have ${this.account && this.account.id}; received for ${data.accountId})`);
            return;
          }

          if (!checkIn) {
            console.error("Could not check in");
          }
          else
          if (!this.account) {
            console.error("No account for check in");
          }
          else {
            this.account.checkIns.push(checkIn);
          }
          
          this.$router.go(-1);
        }
      },
      disconnected() {},
    },
  },
  
  mounted() {
    this.fetchAccount();
    this.checkRouteForQR(this.$router.currentRoute.value);
    
    const auth = currentAuth();
    console.log("Connecting to ws with auth", auth);
    if (auth && auth.sub) {
      this.$cable.subscribe({
        channel: "ClientChannel",
        id: auth.sub,
      });
    }
  },
  
  methods: {
    checkInDetails(ci) {
      return timeAgoInWords(ci.timestamp);
    },
    
    // Should probably move to app
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    
    doSignOut() {
      clearAuth();
      const currentRoute = this.$router.currentRoute.value;
      if (currentRoute.fullPath == '/') window.location.reload();
      else this.$router.push('/');
    },
    
    async fetchAccount() {
      this.loading = true;
      try {
        const r = await this.axios.request({url: 'account'});
        this.account = r.data;
        Object.assign(this.accountChanges, this.account);
        
        // Copy latest Check In if available
        if (r.data.latestCheckin) {
          this.lastCheckIn = {};
          Object.assign(this.lastCheckIn, r.data.latestCheckin);
        }
      }
      catch (e) {
        console.error("Error fetching account", e);
      }
      this.loading = false;
    },
    
    checkRouteForQR(route) {
      // Check if the route needs to show a QR
      if (route.meta.qr) {
        this.handleQRRouteChange(route);
      }
      else {
        this.qrCodeName = this.qrCodeData = undefined;
      }
    },
    
    handleQRRouteChange(newRoute) {
      if (!newRoute.name) return;
      
      if (this.qrCodeName === newRoute.name) {
        // Nothing to do
        return;
      }

      switch (newRoute.name) {
        case 'checkin': 
          // group run check in
          const auth = currentAuth();
          this.qrCodeData = {
            ts: Date.now(),
            sub: auth?.sub
          };
          break;

        default: 
          return;
      }
    },
    
    cancelQRCode(e) {
      this.$router.push('/');
    },
    
    doSendProfileInfo(e) {
      e && e.preventDefault();
      
      this.loading = true;
      this.axios.request({
        method: 'put',
        url: `account`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: { account: this.accountChanges }
      })
      .then(r=> {
        console.log("info update resp", r.data);
        Object.assign(this.account, r.data); // yikes.
        this.loading = false;
        if (!this.account.emergencyPhone || this.account.emergencyPhone.length < 1)
          shakeElement(this.$refs.deetsBox);
      })
      .catch(e => {
        console.error("err", e);
        this.loading = false;
        shakeElement(this.$refs.deetsBox);
      })
      
    },
    
    askForAvatar(e) {
      e && e.preventDefault();
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    
    handleNewAvatarFile(e) {
      const f = e.target.files[0];

      // Immediately show the image given. It's quick and from-memory.
      this.avatarFileBlobURL = URL.createObjectURL(f);
        
      // We can't set an avatar image until the account exists,
      // so we'll always have an ID to reference
      if (this.account.id) {
        // PUT the image as FormData
        const data = new FormData();
        data.append('account[avatar]', f);
        
        this.axios.request({
          method: 'put',
          url: `account`,
          headers: {
            'Accept': 'application/json',
          },

          data,
          
          onUploadProgress: (p) => {
            const totalLength = p.lengthComputable ? p.total : p.target.getResponseHeader('content-length') || p.target.getResponseHeader('x-decompressed-content-length');
            console.log("onUploadProgress", totalLength, p);
            if (totalLength !== null) {
              this.avatarUploadProgress = (Math.round( (p.loaded * 100) / totalLength ));
            }
          }
        })
        .then(r=> {
          console.log("info update resp", r.data);
          // Object.assign(this.account, r.data); // yikes.
          this.avatarUploadProgress = 100;
          this.loading = false;
        })
        .catch(e => {
          console.error("err", e);
          nextTick(() => {
            this.avatarUploadProgress = null;
            this.loading = false;
          })
        })
      }
      else {
        // Save image data to post for creation
        //
        // const fr = new FileReader();
        // fr.onload = () => this.avatarFileDataURL = fr.result;
        // fr.readAsDataURL(f);
      }
    },
    
    showProfileEdit(e) {
      e && e.preventDefault();
      this.$router.push('/profile');
    },
    
    showCheckIn(ci) {
      this.$router.push({name: 'checkInDetails', params: {checkInId: ci.id}})
    },
    
    cancelEvent(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
  }
}
</script>