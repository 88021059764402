<template>
  <div class="participate" :class="{loading: isLoading}">
    <article class="info box" v-if="status=='indeterminate'">
      <loading-spinner />
      <h1>Contacting Host</h1>
    </article>
    <article class="info box" v-else-if="status=='interogating'">
      <h1>{{ targetName || 'Check In' }}</h1>
      <form @submit="onFormSubmit">
        <div class="form-row multiline" v-for="q in questions" :key="q.id">
          <label>{{q.text}}</label>
          <input type="Text" v-model="answers[q.id]">
        </div>
        <div class="model controls">
          <button @click.prevent="doSendCheckIn">Check In</button>
        </div>
      </form>
    </article>
    <article class="info box" v-else-if="status=='success'">
      <status-animation :status="animStatus" />
      <h1 v-if="message">{{ message }}</h1>
      <h2 v-if="messageDetail">{{ messageDetail }}</h2>
      <router-link class="profile-link" to="/">To My Profile»</router-link>
    </article>
  </div>
</template>

<style lang="scss">
  .participate {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
//    background: url('~Shared/assets/img/aravaipa_logo_color_300.png') 50% 50% no-repeat;
    background: url('~Shared/assets/img/aravaipa_virtual_logo.svg') 50% 50% no-repeat;
    .info {
      text-align: center;
      padding: 3rem;
      
      h2 { font-size: 1rem; }
    
       .spinner {
        position: static;
        transform: none;
        margin: 0 auto;
      }
      
      .profile-link {
        display: block;
      }
    }
    
    .status-animation {
      width: 40vw;
      max-width: 300px;
    }
  }
</style>

<script>

import { nextTick } from 'vue'
import StatusAnimation from 'Shared/components/status-animation'

export default {
  name: 'Participate',
  components: { StatusAnimation },

  props: {
    targetUUID: {
      type: String
    }
  },

  data() {
    return {
      status: 'indeterminate',
      animStatus: 'indeterminate', // separate status so we can wait a tick for elements to appear.
      message: null,
      targetName: undefined,
      questions: [],
      answers: {}, // integer keys
    }
  },
  
  computed: {
    isLoading() {
      return (
        status == 'indeterminate' ||
        status == 'loading'
      );
    }
  },
  
  mounted() {
    // Wait for route to set props
    nextTick(() => this.doGetRequiredParams());
  },
  
  methods: {
    doGetRequiredParams() {
      this.axios.request({
        method: 'get',
        url: `account/participate?u=${this.targetUUID}`,
      })
      .then(r=> {
        console.debug('required params:', r);
        this.questions = r.data.questions;
        this.targetName = r.data.name;
        this.status = 'interogating'
      });
    },
        
    doSendCheckIn() {
      if (window.location.search.match(/test/)) {
        console.warn("Running as Test (no server contact)");
        setTimeout(()=> this.checkInSuccess(), 1000);
        return
      }
      
      let startTime = Date.now();
      
      this.axios.request({
        method: 'post',
        url: `account/participate`,
        data: { 
          target: this.targetUUID,
          answers: this.answers
        }
      })
      .then(r=> {
        let deltaTime = 500 - (Date.now() - startTime); // status for at least .5s
        if (deltaTime < 1) deltaTime = 1;
        console.debug(`participate check-in resp in ${deltaTime}`, r.data);
        setTimeout(()=> this.checkInSuccess(), deltaTime);
        // this.checkInSuccess();
      })
      .catch(e => {
        console.error("err", e);
        this.loading = false;
        this.message = 'Could not check in';
        switch (e.response.status) {
          case 400: this.messageDetail = 'Invalid code'; break;
          case 404: this.messageDetail = 'This code no longer exists'; break;
          case 500: this.messageDetail = 'Server error (please try again shortly)'; break;
          default:  this.messageDetail = 'Please see the group host';
        }
        // Wait for status-animation to show with status=indeterminate
        // before changing status to success
        nextTick(()=> this.status = 'error');
      });
    },
    
    checkInSuccess() {
      this.loading = false;
      this.message = null;
      this.messageDetail = null;
      // Wait for status-animation to show with status=indeterminate
      // before changing status to success
      this.status = 'success';
      nextTick(()=> this.animStatus = 'success');
      
      // Wait for user to realize and experience the sheer success 
      // of the thing
      if (!window.location.search.match(/test/)) {
        setTimeout(() => {
          this.$router.replace('/')
        }, 2400);
      }
    }
  }
}
</script>