export function timeAgoInWords (ts, just_now) {
  var from, to, difference, periods, lengths, tense, i, period

  just_now = (typeof just_now != 'undefined') ? just_now : true

  from = new Date(ts)
  to   = new Date()

  difference = Math.abs(to - from) / 1000

  periods = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year']
  lengths = [60, 60, 24, 7, 4.35, 12]

  tense   = 'ago'

  if (difference < 60 && just_now)
    return (difference > 30) ? '1 minute ago' : 'Just now'

  for (i = 0; difference >= lengths[i] && i < lengths.length; i++) {
    difference /= lengths[i]
  }

  difference = Math.round(difference)

  period = (difference !== 1) ? periods[i] + 's' : periods[i]

  return [difference, period, tense].join(' ')
}

export function shakeElement(elm) {
  console.log("will shake elm", elm);
  elm.classList.add('shake')
  const stopShaking = (e) => { 
    elm.classList.remove('shake');
    elm.removeEventListener("webkitAnimationEnd", stopShaking);
    elm.removeEventListener("animationend", stopShaking);
    elm.removeEventListener("oanimationend", stopShaking);
  };
  elm.addEventListener("webkitAnimationEnd", stopShaking);
  elm.addEventListener("animationend", stopShaking);
  elm.addEventListener("oanimationend", stopShaking);
}

// Just use import * as clipboard from 'clipboard-polyfill/text'
export function copyToClipboard(el) {
    var oldContentEditable = el.contentEditable,
        oldReadOnly = el.readOnly,
        range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');
}