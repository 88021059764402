<template>
  <router-view />
</template>

<style src="Shared/assets/css/colors.scss" lang="scss"></style>
<style src="Shared/assets/css/style.scss" lang="scss"></style>
<style src="Shared/assets/css/forms.scss" lang="scss"></style>
<style src="Shared/assets/css/button.scss" lang="scss"></style>


<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

