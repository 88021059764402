import { createWebHashHistory, createWebHistory, createRouter } from "vue-router";
import { getAuthIsExpired, renewAuth, isLoggedIn, currentAuth } from 'Shared/lib/authentication'

import Profile from '@/views/profile'
import Login   from '@/views/login'
import Waver   from '@/views/waver'
import Participate from '@/views/participate'

const routes = [
  // Async component example
  //   component: () => import(/* webpackChunkName: "crossings" */ '@/views/event-sources')
  //
  { path: '/',                        component: Profile },
  { path: '/profile',                 component: () => import(/* webpackChunkName: "detail" */ '@/views/profile-edit'),
                                                              name: 'profileEdit' },
  { path: '/check-in',                component: Profile,     name: 'checkin', meta: { qr:true } }, // Pull method (admin scans client's code)
  { path: '/check-in/:checkInId',     component: () => import(/* webpackChunkName: "detail" */ '@/views/check-in-details'),  
                                                              name: 'checkInDetails', props: true },
  { path: '/participate/:targetUUID', component: Participate, name: 'participate', props: true},
  { path: '/login',                   component: Login,       name: 'login' },
  { path: '/wavers/:waverId',         component: Waver,       name: 'waver', props: true},
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

function redirectToLogin(to, from, next) {
  next({ name: 'login', query: {...to.query, t: to.path} });
}

router.beforeEach(async (to, from, next) => {
  
  // Check if the token is expired
  if (getAuthIsExpired()) {
    console.warn("Auth: expired");
    try {
      await renewAuth();
    }
    catch (err) {
      console.error("Could not renew auth", err)
      
      redirectToLogin(to, from, next);
      return;
    }
  }
  
  // Token, if any, is not expired
  const loggedIn = isLoggedIn('MyAravaipa');
  console.log(`Auth: ${loggedIn ? 'Logged In' : 'Unauthenticated'}`, currentAuth());

  if (to.name === 'login') {
    // on login page
    if (loggedIn) {
      next(from.query.t || '/');
    }
    else {
      next();
    }
  }
  else {
    if (!loggedIn) {
      // not on login page, but not logged in
      redirectToLogin(to, from, next);      
    }
    else {
      // not on login & logged in
      next();
    };
  }
});


export default router;