<template>
  <div class="qr-overlay" @click="didClickOverlay">
    <div class="qr-container">
      <slot />
      <img class="qr" alt="QR Code" ref="qr" />
      <div class="after">
        <slot name="after" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
    @keyframes qrbg { 
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }

  .qr-container {
    --sq-size: min(400px, min(100vw, 100vh));
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
//    aspect-ratio: 1/1.1;
    width: var(--sq-size);
    height: calc(var(--sq-size) + 2em);
    z-index: 101;
    border-radius: var(--br);
    box-shadow: 0 0 15px rgba(0,0,0,0.5);
    
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab, var(--accent-color));
    background-size: 800% 800%;
    animation: qrbg 10s ease infinite;
    
    h1 {
      margin: 0 0 5px;
      padding: 0;
      text-align:center;
      color: black;
      font-size: 22px;
      line-height: 2em;
    }
    
    img.qr {
//      position: fixed;
//      aspect-ratio: 1;
//      width: calc(var(--sq-size) - 2em);
//      height: var(--sq-size);
      position: absolute;
      top: 2.5em;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1em;
      width: calc(100% - 3em);
      margin: 0;
      pointer-events: none;
    }
    
    .after {
      color: black;
      position: absolute;
      width: stretch;
      bottom: 0;
      text-align: right;
      font-weight: bold;
      font-size: 1.15rem;
      display: block;
      padding: 0 1.5rem;
    }
  }

  .qr-overlay {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    z-index: 100;
  }
</style>

<script>
import QRCode from 'qrcode'
export default {
  name: 'QRCode',
  props: {
    qrData: {
      type: Object,
      default: () => {}
    }
  },
  
  emits: ['close'],
  
  watch: {
    qrData(newVal) {
      this.updateQRDisplay();
    }
  },
  
  mounted() {
    this.updateQRDisplay();
  },
  
  methods: {
    async updateQRDisplay() {
      const uri = await this.getQRDataURI(this.qrData);
      this.$refs.qr.src = uri;
    },
    
    async getQRDataURI(data) {
      const payload = btoa(JSON.stringify(data))
      console.log("Show QR Data", data, payload);

      const options = {
        mode: 'alphanumeric', 
        errorCorrectionLevel: 'H',
        scale: 10,
        margin: 0,
        color: {
          light: '#00000000',
          dark: '#000000'
        }
      };

      return QRCode.toDataURL(payload, options);
    },
    
    didClickOverlay(e) {
      e && e.preventDefault();
      this.$emit('close');
    }
  }
  
}
</script>