<template>
  <article class="waver-view">
    <loading-spinner v-if="loading" />
    <h1>Waiver</h1>
    <h2>Please accept the following waiver to continue</h2>
    
    <p class="sosumi" v-if="waver.content">{{ waver.content }}</p>
    
    <div class="controls">
      <button class="go" @click="doCancel">❌</button>
      <button class="go" @click="doAccept">Accept</button>
    </div>      
  </article>
</template>

<style lang="scss">
  .waver-view {
    .sosumi {
      text-align: justify;
      padding: 0 1em;
    }
  
    .controls {
      padding-bottom: 3em;
    }
  }
</style>

<script>
export default {
  props: {
    waverId: {
      type: String,
    }
  },
  
  data() {
    return {
      loading: false,
      error: null,
      waver: {},
    }
  },
  
  async mounted() {
    try {
      this.loading = true;
      const response = await this.axios.request({
        method: 'get',
        url: `wavers/${this.waverId}`,
        headers: {
          'Accept': 'application/json',
        },
      });
      
      this.waver = response.data;
      this.loading = false;
    }
    catch (err) {
      console.error(err);
      this.error = err;
      this.loading = false;
    }

  },
  
  methods: {
    doCancel() {
      // What do?
    },
    
    async doAccept() {
      try {
        this.loading = true;
        const response = await this.axios.request({
          method: 'put',
          url: `account/accept_waver`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: {
            waverId: this.waverId
          }
        });
        
        this.loading = false;
        
        const route = this.$router.currentRoute.value;
        this.$router.push(route.query.t || '/');
        
        this.$gtag.event('waver', { id: this.waverId });
      }
      catch (err) {
        console.error("Error while accepting: ", err);
        this.error = err;
        this.loading = false;
        
        if (err.response.status == 401) {
          // redirect to login
          console.debug("redirect to login");
          const route = this.$router.currentRoute.value;
          const to = { name: 'login', query: route.query };
          this.$router.push(to);
        }
      }
    }
  }
}
</script>